import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$toastContent = ToastificationContent

Vue.prototype.$defaultCardFrontImage = 'https://pngimg.com/uploads/credit_card/credit_card_PNG204.png'
Vue.prototype.$defaultCardBackImage = 'https://www.nicepng.com/png/detail/349-3492273_credit-card-back-back-of-credit-card-icon.png'
Vue.prototype.$defaultCardTypeImage = require('@/assets/images/placeholders/no-image-available.png')
Vue.prototype.$defaultCardTypeFinishingImage = require('@/assets/images/placeholders/no-image-available.png')

Vue.prototype.$attachQuerySymbol = value => (value.length > 0 ? '&' : '?')
Vue.prototype.$capitalizeString = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
Vue.prototype.$isEmpty = value => {
  if (value === undefined) return true
  if (value === null) return true
  if (value === '') return true
  if (value === 'Not Set') return true

  if (typeof (value) === 'object') {
    if (Object.keys(value).length <= 0) return true
  }

  if (typeof (value) === 'array') {
    if (Object.keys(value).length <= 0) return true
  }
  return false
}
Vue.prototype.$isNotEmpty = value => {
  return !Vue.prototype.$isEmpty(value)
}

Vue.prototype.$toastDanger = (title = '', text = '') => {
  Vue.prototype.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'AlertCircleIcon',
        text,
        variant: 'danger',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}
Vue.prototype.$toastSuccess = (title = '', text = '') => {
  Vue.prototype.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'CheckCircleIcon',
        text,
        variant: 'success',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}
Vue.prototype.$toastWarning = (title = '', text = '') => {
  Vue.prototype.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'AlertCircleIcon',
        text,
        variant: 'warning',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}
Vue.prototype.$toastInfo = (title = '', text = '') => {
  Vue.prototype.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'InfoCircleIcon',
        text,
        variant: 'info',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}

Vue.prototype.$handleResponseError = (error, callback = null) => {
  // alert('an error occured')
  console.log(error.response)

  Vue.prototype.$toastDanger('An error occured', error.response?.data?.message || '')
  if (Vue.prototype.$isNotEmpty(callback)) callback()
}

Vue.prototype.$makeValidHttpLink = (urlString) => {
  if (!/^https?:\/\//i.test(urlString)) {
    urlString = 'http://' + urlString
  }
  return urlString
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
