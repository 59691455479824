<template>
  <div :style="backgroundImage" class="full-screen full-screen-overflow">
    <div class="d-flex flex-column align-items-between h-100">
      <div class="d-flex flex-column align-items-center justify-content-center h-100 text-light">
        <h1 class="text-light"> Digital Business Card </h1>

        <h5 class="text-light text-center mt-3">Welcome to networking.sg, Create and manage your business card </h5>

        <a href="https://dashboard.steadylah-network.com/auth/login?redirect=%2Fbusiness-cards" class="w-100 w-md-25 text-center">
          <b-button class="mt-1 custom-button-style">
            <h4 class="text-light">
              Steadylah Network
            </h4>
          </b-button>
        </a>
      </div>

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppFooter from '@core/layouts/components/AppFooter.vue'

export default {
  components: {
    AppFooter,
  },
  data() {
    return {
      backgroundImageUrl: require('@/assets/images/background/dark-blue.jpg'),
    }
  },

  computed: {
    backgroundImage() {
      return `
        background-image: url("${this.backgroundImageUrl}");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      `
    },
  },
}
</script>

<style>

</style>