import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserInfo } from '@/auth/utils'
// import auth from './routes/auth'
// import businessCards from './routes/business-cards'
// import businessCardsTypes from './routes/business-cards-types'
import dashboard from './routes/dashboard'
import docs from './routes/docs'
import pages from './routes/pages'
// import stripe from './routes/stripe'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/HomePage.vue'),
      meta: {
        allowAnonymous: true,
        layout: 'full',
      },
    },

    ...docs,
    ...dashboard,
    ...pages,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const normalUserAllowedSections = [
  'Business Cards',
]

router.beforeEach((to, _, next) => {
  // Vue.nextTick(() => {
  //   router.app.$store.dispatch('auth/getLoggedInUser', { router_app: router.app, publicPage: to.meta.allowAnonymous || null })
  // })

  // const isLoggedIn = isUserLoggedIn()
  // const userInfo = getUserInfo()

  // // if the page does not require authorization, allow access
  // if (to.meta.allowAnonymous && !to.meta.auth) {
  //   return next()
  // }
  // // if user has logged in proceed with the requested page
  // if (isLoggedIn && to.meta.auth) {
  //   return next({ path: '/', query: { redirect: to.fullPath } })
  // }

  // if (to.meta.section) {
  //   if (!userInfo.is_admin) {
  //     if (!normalUserAllowedSections.includes(to.meta.section)) {
  //       return next({ path: '/unauthorized', query: { redirect: to.fullPath } })
  //     }
  //   }
  // }
  // // if user not authorised send to authentication page
  // if (!isLoggedIn && !to.meta.auth) {
  //   if (!to.meta.allowAnonymous) {
  //     return next({ path: '/auth/login', query: { redirect: to.fullPath } })
  //   }
  // }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
