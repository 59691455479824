import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import VSelect from 'vue-select'
// import { StripePlugin } from '@vue-stripe/vue-stripe';

Vue.use(BootstrapVue)

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('v-select', VSelect)

// const options = {
//   pk: process.env.STRIPE_PUBLISHABLE_KEY,
//   stripeAccount: process.env.STRIPE_ACCOUNT,
//   apiVersion: process.env.API_VERSION,
//   locale: process.env.LOCALE,
// }
// const options = {
//   pk: 'pk_test_51MGaTvECCuXqKKn7TMyv31CIXXl0cj6tGofNpsvnYvmCnlEVDoF2fkmtCrwMf5Mrlap1OtUrZsObwWyFgDkMSZJ300UQj5oT4i',
//   stripeAccount: 'acct_1MGaTvECCuXqKKn7',
//   apiVersion: 'v3',
//   locale: 'auto',
// }

// Vue.use(StripePlugin, options)