export default [
  {
    path: '/:cardNumber',
    name: 'redirect-dynamic-url',
    props: true,
    component: () => import('@/views/redirect/Redirect.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Dashboard', active: true },
      ],
      pageTitle: 'Dashboard | BETA',
      rule: 'editor',
      section: 'Dashboard',
      layout: 'full',
      allowAnonymous: true,
    },
  },
]
