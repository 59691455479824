<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity="1"
    rounded="sm"
  >
    <div
      v-if="!isLoading"
      :style="backgroundImage"
      class="full-screen full-screen-overflow"
    >
      <div class="d-flex flex-column justify-content-between align-items-center" style="height:100%;">
        <div class="p-2 d-flex justify-content-center w-100" style="height:100%;">
          <b-row class="d-flex justify-content-center w-100">
            <b-col cols="12" md="6">
              <div class="image d-flex flex-column justify-content-start align-items-center w-100">
                <button class="image-container animation-slide" style="background-color: #e0eff5;">
                  <img
                    :src="cardDetails.static_page_details && $isNotEmpty(cardDetails.static_page_details.user_image) ? cardDetails.static_page_details.user_image : imgUrl"
                    height="100%"
                    width="100%"
                  >
                </button>

                <div class="w-100">
                  <h1 class="text text-size-biggest mt-2 text-center mt-1 title-style">{{ cardDetails.static_page_details.display_name }}</h1>

                  <!-- <div class="line-separator-style" /> -->

                  <div v-if="false" class="text text-center"> 
                    <a :href="`tel:${cardTemplateDetails.mobile_number.value}`">
                      <h5 class="text"> <i class="fa fa-phone"> </i> &nbsp; {{ cardTemplateDetails.mobile_number.value }}</h5>
                    </a>
                  </div>
                  <div v-if="false" class="text text-center">
                    <a :href="`mailto:${cardTemplateDetails.mobile_number.value}`">
                      <h5 class="text"> <i class="fa fa-envelope"> </i> &nbsp; {{ cardTemplateDetails.email.value }}</h5>
                    </a>
                  </div>
                </div>

                <!-- <div class="text mt-3 text-center"> 
                  <span>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quo asperiores impedit nemo alias aut at iure saepe qui libero, vero molestiae sit!</span> 
                </div> -->

                <div
                  v-if="$isNotEmpty(cardDetails.static_page_details.links)"
                  class="d-flex flex-column align-items-center w-100"
                  style="min-width: 24rem;"
                >
                  <div class="d-flex flex-row justify-content-center align-items-center w-100">
                    <div class="line-separator-style" />
                    <h4 class="m-0 mx-1 title-style w-25 text-center"> Links </h4>
                    <div class="line-separator-style" />
                  </div>

                  <!-- <b-button class="custom-button-style mt-1">&nbsp; Link &nbsp;</b-button> -->
                  <template v-if="cardDetails.static_page_details.links[0] != ''">
                    <b-button
                      v-for="link in cardDetails.static_page_details.links"
                      :key="link.id"
                      class="custom-button-style mt-1 w-100 py-1"
                    >
                      <a :href="$makeValidHttpLink(link.redirected_link)">
                        <b>
                          {{ $isNotEmpty(link.reflected_name) ? link.reflected_name : link.redirected_link }}
                        </b>
                      </a>
                    </b-button>
                  </template>

                  <div class="d-flex flex-row justify-content-center align-items-center w-100">
                    <div class="line-separator-style" />
                  </div>
                </div>


                <!-- <div class="mt-2 d-flex flex-column align-items-center w-100">
                  <a class="title-style-darker" :href="makeValidHttpLink(cardDetails.static_link)">
                    Powered by Digital Business Card
                  </a>
                </div> -->
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="mb-2 text-light d-flex flex-row gap-3 justify-content-between align-items-center" style="gap: 3em;">
          <div class="">
            <i class="fa fa-twitter "></i>
          </div>
          <div>
            <i class="fa fa-facebook-f"></i>
          </div>
          <div>
            <i class="fa fa-instagram"></i>
          </div>
          <div>
            <i class="fa fa-linkedin"></i>
          </div>
        </div>

        <!-- <app-footer /> -->
      </div>
    </div>
  </b-overlay>
</template>

<script>
import AppFooter from '@core/layouts/components/AppFooter.vue'
import { makeColumnLayout } from 'echarts/lib/layout/barGrid'

export default {
  props: {
  },
  components: {
    AppFooter,
  },
  data() {
    return {
      isLoading: true,
      cardNumber: null,
      cardDetails: {
        static_page_details: {},
      },
      cardTemplateDetails: {},

      // backgroundImageUrl: require('@/assets/images/background/dark-blue.jpg'),
      backgroundImageUrl: require('@/assets/images/background/downlight.jpg'),
      https: require('https'),

      imgUrl: require('@/assets/images/placeholders/no_user_image.png'),
    }
  },
  computed: {
    backgroundImage() {
      return `
        background-image: url("${this.backgroundImageUrl}");
        // background-repeat: no-repeat;
        // background-size: 100% 100%;
        background-repeat: repeat-x;
        background-position: 50% 0%;
        background-color: #011422;
      `
    },
  },
  created() {
    this.cardNumber = this.$route.params.cardNumber
  },
  mounted() {
    this.redirectToDynamicLink()
  },
  methods: {
    redirectToDynamicLink() {
      const agent = new this.https.Agent({
        rejectUnauthorized: false,
        requestCert: false,
        agent: false,
      });

      this.$http
        .get(`/api/business-cards-links/${this.cardNumber}`, {
          headers: {
            httpsAgent: agent,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          },
        })
        .then(response => {
          const { success, message, output, pagination } = response.data
          if (!success) {
            window.location.assign('http://dashboard.steadylah-network.com/auth/login')
            // this.$router.push({name: 'error-404'})
            return
          }
          if (output.stripe_payment_status === 'paid') {
            this.cardDetails = output
            this.cardTemplateDetails = output.card_template_details
          } else {
            window.location.assign('http://dashboard.steadylah-network.com/auth/login')
            return
            // this.$router.push({name: 'error-404'})
          }

          if (this.cardDetails.landing_page_type === 'dynamic') {
            if (this.$isNotEmpty(output.active_link)) {
              if (output.active_link !== output.static_link) {
                output.active_link = this.$makeValidHttpLink(output.active_link)
                // window.location.href = `${output.active_link}`
                window.location.assign(`${output.active_link}`)
              }
            }
          } else {
            this.isLoading = false
          }
        })
        .catch(error => {
          window.location.assign('http://dashboard.steadylah-network.com/auth/login')
          // this.$router.push({name: 'error-404'})
          // this.$handleResponseError(error, () => { this.isLoading = false })
        })
    },

    makeValidHttpLink(urlString) {
      if (!/^https?:\/\//i.test(urlString)) {
        urlString = 'http://' + urlString
      }
      return urlString
    },
  },
}
</script>

<style lang="scss" scoped>
.text-color-primary {
  color: #fff;
}
.title-style {
  font-family: 'Libre Baskerville', serif;
  color: #fff;
}
.title-style-darker {
  font-family: 'Libre Baskerville', serif;
  color: #0e396ef9;
}

.border-round {
  border: 1px solid;
  border-radius: 50% !important;
  border-color: #1764c4;
}

.image-container {
  height: 120px;
  width: 120px;
  border-radius: 50% !important;
  border-color: #1764c4;
  overflow: hidden;
}

.custom-button {
  flex: 1 1 auto;
  margin: 10px;
  padding: 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
 /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
}
.custom-button:hover {
  background-position: right center; /* change the direction of the change here */
}
.custom-button-2 {
  background-image: linear-gradient(to right, #13365c 0%, #13365c 51%, #13365c 100%);
  color: white;
}
hr {
  border: none;
  height: 12px;
  /* Set the hr color */
  color: #333 !important;  /* old IE */
  background-color: #333 !important;  /* Modern Browsers */
}
.line-separator-style {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 0;
  margin-right: 0;
  height: 1px;
  background-color: #545050 !important;  /* Modern Browsers */
  width: 100%;
}

@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';
</style>
